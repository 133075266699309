import React from 'react'
import {
  Layout,
  Navbar,
  Box,
  Container,
  Text,
  Flex,
  Image,
  Link,
  Footer,
  Reveal,
} from '../components'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'

// Styled components

const CustomerImage = styled(Image)`
  display: inline-block;
  padding: 1.5em;
  max-height: 8em;
  vertical-align: middle;
  max-width: 100%;
`

// Main component

export const query = graphql`
  query {
    topBackgroundImage: file(relativePath: { eq: "top_background.jpg" }) {
      ...getImage
    }

    imageStars: file(relativePath: { eq: "image-stars.jpg" }) {
      ...getImage
    }

    imageBerries: file(relativePath: { eq: "image-berries.jpg" }) {
      ...getImage
    }

    imageUnicredit: file(relativePath: { eq: "client.svg" }) {
      ...getImage
    }

    imageYepjet: file(relativePath: { eq: "yepjet.svg" }) {
      ...getImage
    }

    imageRadicalbit: file(relativePath: { eq: "radicalbit.svg" }) {
      ...getImage
    }

    imageUtego: file(relativePath: { eq: "utego.svg" }) {
      ...getImage
    }

    imageBsmart: file(relativePath: { eq: "bsmart.svg" }) {
      ...getImage
    }

    imageAlpino: file(relativePath: { eq: "alpinoitaliano.svg" }) {
      ...getImage
    }

    imageYobicash: file(relativePath: { eq: "yobicash.svg" }) {
      ...getImage
    }

    imageTecniplast: file(relativePath: { eq: "tecniplast.svg" }) {
      ...getImage
    }

    imageViolet: file(relativePath: { eq: "violet.png" }) {
      ...getImage
    }
  }
`

interface IIndexPageProps {
  data: any
  intl: IntlShape
}

const IndexPage: React.SFC<IIndexPageProps> = (props) => {
  return (
    <Layout
      backgroundImage={`url(${props.data.topBackgroundImage.publicURL}), url(${props.data.topBackgroundImage.publicURL})`}
      backgroundSize='93.75em auto'
      backgroundPosition='center 1.25em, center 105%'
    >
      <>
        <Navbar />

        <Container>
          <Box padding='12em 8em' paddingLg='8em 2em' textAlign='center'>
            <Reveal>
              <Text
                color='white'
                size='2.5em'
                weight={300}
                lineHeight={1.5}
                letterSpacing='0.05em'
                sizeSm='1.8em'
              >
                {props.intl.formatMessage({ id: 'home.slogan' })}
              </Text>
            </Reveal>
          </Box>

          <Reveal>
            <Flex alignItems='center' wrapLg='wrap'>
              <Box flex='1 1 100%' flexLg='0 0 100%' textAlignLg='center'>
                <Image
                  src={props.data.imageStars.publicURL}
                  radius='0.2em'
                  width='100%'
                  tilt
                />
              </Box>

              <Box flex='0 0 28em' flexLg='0 0 100%'>
                <Box padding='0em 0em 0em 4em' paddingLg='4em 2em 0em 2em'>
                  <Box>
                    <Text
                      color='white'
                      size='2.2em'
                      weight={300}
                      letterSpacing='0.05em'
                      maxWidth='10em'
                    >
                      {props.intl.formatMessage({ id: 'home.title1' })}
                    </Text>
                  </Box>

                  <Box margin='1em 0em 0em 0em'>
                    <Text
                      color='silver'
                      size='1.2em'
                      lineHeight={1.5}
                      letterSpacing='0.05em'
                    >
                      {props.intl.formatMessage({ id: 'home.content1' })}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Reveal>

          <Box padding='8em 10em' paddingLg='8em 2em' textAlign='center'>
            <Reveal>
              <>
                <Text
                  color='white'
                  size='2.2em'
                  weight={300}
                  lineHeight={1.5}
                  letterSpacing='0.05em'
                  sizeSm='1.8em'
                >
                  {props.intl.formatMessage({ id: 'home.catch1' })}
                </Text>

                <Box margin='2em 0em 0em 0em'>
                  <Link to='/services/'>
                    <Text color='lightPurple' size='1.2em' italic>
                      {props.intl.formatMessage({ id: 'home.link1' })}
                    </Text>
                  </Link>
                </Box>
              </>
            </Reveal>
          </Box>

          <Reveal>
            <Flex direction='row-reverse' alignItems='center' wrapLg='wrap'>
              <Box flex='1 1 100%' flexLg='0 0 100%' textAlignLg='center'>
                <Image
                  src={props.data.imageBerries.publicURL}
                  radius='0.2em'
                  width='100%'
                  maxWidth='30em'
                  tilt
                />
              </Box>

              <Box flex='0 0 28em' flexLg='0 0 100%'>
                <Box padding='0em 4em 0em 0em' paddingLg='4em 2em 0em 2em'>
                  <Box>
                    <Text
                      color='white'
                      size='2.2em'
                      weight={300}
                      letterSpacing='0.05em'
                      maxWidth='10em'
                    >
                      {props.intl.formatMessage({ id: 'home.title2' })}
                    </Text>
                  </Box>

                  <Box margin='1em 0em 0em 0em'>
                    <Text
                      color='silver'
                      size='1.2em'
                      lineHeight={1.5}
                      letterSpacing='0.05em'
                    >
                      {props.intl.formatMessage({ id: 'home.content2' })}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Reveal>

          <Box
            padding='8em 10em 0em 12em'
            paddingLg='8em 2em 0em 2em'
            textAlign='center'
          >
            <Reveal>
              <>
                <Text
                  color='white'
                  size='2.2em'
                  weight={300}
                  lineHeight={1.5}
                  letterSpacing='0.05em'
                  sizeSm='1.8em'
                >
                  {props.intl.formatMessage({ id: 'home.catch2' })}
                </Text>

                <Box margin='2em 0em 0em 0em'>
                  <Link to='/contact/'>
                    <Text color='lightPurple' size='1.2em' italic>
                      {props.intl.formatMessage({ id: 'home.link2' })}
                    </Text>
                  </Link>
                </Box>
              </>
            </Reveal>
          </Box>

          <Box
            padding='12em 5em 8em 5em'
            paddingLg='12em 0em 8em 0em'
            textAlign='center'
          >
            <Reveal>
              <>
                <Box>
                  <Text
                    color='white'
                    size='1.5em'
                    weight={300}
                    letterSpacing='0.05em'
                  >
                    {props.intl.formatMessage({ id: 'home.customers' })}
                  </Text>
                </Box>

                <Box margin='1em 0em 0em 0em'>
                  <CustomerImage src={props.data.imageUnicredit.publicURL} />
                  <CustomerImage src={props.data.imageYepjet.publicURL} />
                  <CustomerImage src={props.data.imageRadicalbit.publicURL} />
                  <CustomerImage src={props.data.imageUtego.publicURL} />
                  <CustomerImage src={props.data.imageBsmart.publicURL} />
                  <CustomerImage src={props.data.imageAlpino.publicURL} />
                  <CustomerImage src={props.data.imageYobicash.publicURL} />
                  <CustomerImage src={props.data.imageTecniplast.publicURL} />
                  <CustomerImage src={props.data.imageViolet.publicURL} />
                </Box>
              </>
            </Reveal>
          </Box>

          <Box padding='0em 0em 8em 0em'>
            <Reveal>
              <Footer />
            </Reveal>
          </Box>
        </Container>
      </>
    </Layout>
  )
}

export default injectIntl(IndexPage)
